import React, { Fragment } from "react";
import { Typography, Box, Grid } from '@mui/material';

import { useAuth } from "../../contexts/AuthContext";

export default function RoomName(props) {
  const { mobile } = props;
  const { display } = useAuth();

  if (mobile) {
    return (
      <Box style={{}}>
        <Typography
          align="center"
          sx={{
            color: "white",
            fontWeight: "bold",
            fontSize: {
              xs: "2rem",
              sm: "2rem",
              md: "4rem"
            }
          }}
          noWrap={true}>
          {display &&
            (<Fragment>{display.name}</Fragment>)
          }
        </Typography>
      </Box >
    );
  } else {
    return (
      <Grid container item spacing={0}
        direction="row"
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{
          height: '100%'
        }} >
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} align="left">
          <Typography
            sx={{
              color: "white",
              fontWeight: "bold",
              marginLeft: "20px",
              fontSize: {
                xs: "2rem",
                sm: "2rem",
                md: "3rem",
                lg: "4rem",
                xl: "4rem"
              }
            }}
            noWrap={true}>
            {display && <span>{display.name}</span>}
          </Typography>
        </Grid >
      </Grid>
    );
  }
}