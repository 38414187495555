import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator, serverTimestamp, Timestamp, collection } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAuth, connectAuthEmulator } from "firebase/auth";


const app = initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
});

export const firestore = getFirestore(app);

export const database = {
  displays: collection(firestore, "displays"),
  events: collection(firestore, "events"),
  spaces: collection(firestore, "spaces"),
  companies: collection(firestore, "companies"),
  devices: collection(firestore, "devices"),
  checkins: collection(firestore, "checkins"),
  formatDoc: (fdoc) => {
    return { id: fdoc.id, ...fdoc.data() };
  },
  getCurrentTimestamp: serverTimestamp,
  setTimeStampFromDate: (date) => {
    return Timestamp.fromDate(date);
  },
  setTimeStampMS: (time) => {
    return Timestamp.fromMillis(time);
  },
};

export const auth = getAuth(app);
export const functions = getFunctions(app, "europe-west1")

if (process.env.NODE_ENV === "development") {
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectFirestoreEmulator(firestore, 'localhost', 8081);
  connectAuthEmulator(auth, "http://localhost:9099");
}

export default app;
