import React, { Fragment, useEffect, useState } from "react";
import Moment from "react-moment";
import moment from "moment";

import { useAuth } from "../../contexts/AuthContext";
import { useCLOCK } from "../../contexts/ClockContext";
import { useEvents } from "../../contexts/EventsContext";


import { useSTATUS, SCREENSTATUS } from "../../contexts/StatusContext";
import LogoLoader from "../main/LogoLoader";
import Clock from "../main/Clock";
import {
  Box,
  Grid,
  Typography
} from "@mui/material";
import { getDoc } from "firebase/firestore";


export default function Events() {
  const { setStatus } = useSTATUS();
  const { loading, events } = useEvents();
  const { display } = useAuth();
  const { minutes, hours, setBig } = useCLOCK();

  const [space, setSpace] = useState();

  const getSpace = async () => {
    const gS = await getDoc(display.typeSettings.spaceRef);
    setSpace({ id: gS.id, ...gS.data() });
  }

  useEffect(() => {
    if (display) {
      getSpace();
    }
  }, [display])


  useEffect(() => {
    if (!loading) {
      setStatus(SCREENSTATUS.FREE);
    }
    return () => { };
  }, [loading]);

  useEffect(() => {
    if (events.length > 0) {
      const event = events[0];
      const startTime = moment.unix(event.startTime.seconds).seconds(0);
      const now = moment().seconds(0);
      if (startTime.isSameOrBefore(now)) {
        setStatus(SCREENSTATUS.BUSY);
      } else {
        setStatus(SCREENSTATUS.FREE);
      }
    } else {
      setStatus(SCREENSTATUS.FREE);
    }
  }, [events, minutes, hours]);

  useEffect(() => {
    if (events && events.length === 0) {
      setBig(true)
    } else {
      setBig(false);
    }
  }, [events])

  const getOrgName = (event) => {
    var eventOrg = event.organizerName;
    if (space) {
      if (eventOrg === space.name) {
        var eventSub = event.subject;
        if (eventSub.includes("@")) {
          var name = eventSub.split("@");
          return name[0];
        } else {
          return "Spacemer Check-in";
        }
      } else {
        return eventOrg;
      }
    } else {
      return eventOrg;
    }
  }

  return (
    <Box
      sx={{
        height: '100%',
        paddingTop: {
          xs: "40px",
          sm: "40px",
          md: "0px"
        },
      }}>
      {loading && (
        <Grid container item spacing={0}
          direction="row"
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            height: '100%',
          }} >
          <Grid item lg={12} md={12} sm={12} xs={12} align="center">
            <LogoLoader />
          </Grid>
        </Grid>
      )}
      {events.length === 0 && (
        <Grid container item spacing={0}
          direction="row"
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            height: '100%',
          }} >
          <Grid item lg={12} md={12} sm={12} xs={12} align="center">
            <Clock big={true} text={"No upcoming meetings"} />
          </Grid>
        </Grid>
      )}
      {events.length > 0 && (
        <Grid container item spacing={0}
          direction="row"
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{
            height: '100%',
          }} >
          <Grid item lg={12} md={12} sm={12} xs={12} align="center">
            <Box className="infoBoxInner"
              sx={
                {
                  marginLeft: {
                    lg: "40px"
                  },
                  marginRight: {
                    lg: "40px"
                  },
                  padding: {
                    xs: "10px",
                    lg: "20px"
                  }

                }}>
              <Typography
                sx={{
                  fontSize: {
                    xs: "2rem",
                    sm: "2rem",
                    md: "6rem",
                    lg: "4.5rem",
                    xl: "8rem"
                  }
                }}
                noWrap={true} >
                {display.typeSettings.showOrganizer && !display.typeSettings.showTitle && (
                  <Fragment>{getOrgName(events[0])}</Fragment>
                )}
                {!display.typeSettings.showOrganizer && display.typeSettings.showTitle && (
                  <Fragment>{events[0].subject}</Fragment>
                )}
                {display.typeSettings.showOrganizer && display.typeSettings.showTitle && (
                  <Fragment>
                    {events[0].subject}<br></br>
                    {getOrgName(events[0])}
                  </Fragment>
                )}
                {!display.typeSettings.showOrganizer && !display.typeSettings.showTitle && (
                  <Fragment>
                    {events[0].isAllDay ? (
                      <Fragment>
                        All Day
                      </Fragment>
                    ) : (<Fragment>
                      <Moment format="HH:mm" unix>
                        {events[0].startTime.seconds}
                      </Moment>{" "}
                      -{" "}
                      <Moment format="HH:mm" unix>
                        {events[0].endTime.seconds}
                      </Moment>
                    </Fragment>
                    )}
                  </Fragment>
                )}
              </Typography>
              {(display.typeSettings.showOrganizer || display.typeSettings.showTitle) && (
                <Typography
                  sx={{
                    fontSize: {
                      xs: "1.5rem",
                      sm: "1.5rem",
                      md: "4rem",
                      lg: "3rem",
                      xl: "5rm"
                    }
                  }}
                  noWrap={true}>
                  {events[0].isAllDay ? (
                    <Fragment>
                      All Day
                    </Fragment>
                  ) : (<Fragment>
                    <Moment format="HH:mm" unix>
                      {events[0].startTime.seconds}
                    </Moment>{" "}
                    -{" "}
                    <Moment format="HH:mm" unix>
                      {events[0].endTime.seconds}
                    </Moment>
                  </Fragment>
                  )}

                </Typography>
              )}
            </ Box>

            {events.length > 1 && (

              <Box
                className="infoBoxInner2"
                sx={
                  {
                    marginLeft: {
                      lg: "150px"
                    },
                    marginRight: {
                      lg: "150px"
                    },
                    padding: {
                      xs: "20px",
                      lg: "30px"
                    },
                    marginTop: {
                      xs: "20px",
                      lg: "40px"
                    },
                  }
                }
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: "1.5rem",
                      sm: "1.5rem",
                      md: "3rem",
                      lg: "3rem",
                      xl: "4rem"
                    }
                  }}
                  noWrap={true} >
                  {display.typeSettings.showOrganizer && !display.typeSettings.showTitle && (
                    <Fragment>{getOrgName(events[1])}</Fragment>
                  )}
                  {!display.typeSettings.showOrganizer && display.typeSettings.showTitle && (
                    <Fragment>{events[1].subject}</Fragment>
                  )}
                  {display.typeSettings.showOrganizer && display.typeSettings.showTitle && (
                    <Fragment>
                      {events[1].subject}<br></br>
                      {getOrgName(events[1])}
                    </Fragment>
                  )}
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      xs: "1.5rem",
                      sm: "1.5rem",
                      md: "2rem",
                      lg: "2rem",
                      xl: "3rm"
                    }
                  }}
                  noWrap={true}>
                  {events[0].isAllDay ? (
                    <Fragment>
                      All Day - {" "}
                      <Moment format="D/MM" unix>
                        {events[1].startTime.seconds}
                      </Moment>
                    </Fragment>
                  ) : (<Fragment>
                    <Moment format="HH:mm" unix>
                      {events[1].startTime.seconds}
                    </Moment>{" "}
                    -{" "}
                    <Moment format="HH:mm" unix>
                      {events[1].endTime.seconds}
                    </Moment>
                  </Fragment>
                  )}
                </Typography>
              </ Box>
            )}
          </Grid>
        </Grid >
      )
      }

    </Box >
  );
}