import React, { createContext, useState, useContext, useEffect } from "react";
const StatusContext = createContext();

export const SCREENSTATUS = {
  BUSY: "busy",
  FREE: "free",
  LOADING: "loading",
  ERROR: "error",
};

export function useSTATUS() {
  return useContext(StatusContext);
}

export function StatusProvider({ children }) {
  const [status, setStatus] = useState(SCREENSTATUS.LOADING);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener('online', handleStatusChange);

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline]);

  const value = {
    status,
    setStatus,
    isOnline
  };

  return <StatusContext.Provider value={value}>{children}</StatusContext.Provider>;
}

export const StatusConsumer = StatusContext.Consumer;
