import React, { Fragment } from "react";
import { Box } from '@mui/material';

import logoWhite from "../../assets/img/logo-white-full.png";
//import logoGrey from "../../assets/img/logo-grey-full.png";
import { useAuth } from "../../contexts/AuthContext";

export default function Logo() {
    const { display } = useAuth();

    const doReload = async () => {
        window.location.reload();
    };
    return (
        <Fragment>
            {display && display.displaySettings.logo !== "" && (
                <Box
                    component="img"
                    sx={{
                        maxHeight: {
                            xs: 100,
                            sm: 100,
                            lg: 100
                        },
                        maxWidth: {
                            xs: 200,
                            sm: 300,
                            lg: 300,
                            xl: 300
                        }
                    }}
                    onClick={() => { doReload() }}
                    alt="Logo"
                    src={display.displaySettings.logo}
                />)}
            {
                (!display || display.displaySettings.logo === "") && (
                    <Box
                        component="img"
                        sx={{
                            maxHeight: {
                                xs: 100,
                                sm: 100,
                                lg: 150
                            },
                            maxWidth: {
                                xs: 200,
                                sm: 200,
                                lg: 300,
                                xl: 300
                            }
                        }}
                        onClick={() => { doReload() }}
                        alt="Logo"
                        src={display.type === "space" ? logoWhite : logoWhite}
                    />)
            }
        </Fragment>
    );
}
