import React from "react";
import Logo from "../../assets/img/logo-white-icon.png";

export default function LogoLoader(props) {
    const { height = 100, width = 100 } = props;
    return (
        <img
            alt="Logo"
            className="progress-logo"
            height={height}
            width={width}
            src={Logo}
            sx={{
                height: height,
                width: width
            }}
        />
    );
}
