import axios from 'axios';

export const setColor = async (color, localhost) => {
    try {
        if (localhost) {
            axios.get('http://localhost:5005/led/' + color, {}).catch((err) => { });
            return true;
        } else {
            SpacemerDisplay.setColor(color);  // eslint-disable-line no-eval 
            return true;
        }
    } catch (err) {
        return false;
    }
}