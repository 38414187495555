import React from "react";


import { useAuth } from "../../contexts/AuthContext";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
const Swal = require("sweetalert2");
export default function Settings() {
  const { logout, user, display } = useAuth();



  const doLogout = async () => {
    const { value: password } = await Swal.fire({
      title: "Enter admin pin to logout",
      input: "password",
      inputLabel: "Pin",
      inputPlaceholder: "Enter your pin",
      inputAttributes: {
        maxlength: 10,
        autocapitalize: "off",
        autocorrect: "off",
      },
    });

    if (display && password === display.pinCode) {
      await logout("SettingsPin");
      window.location.reload();
    } else {
      await logout("Settings");;
      window.location.reload();
    }
  };

  return (
    <>
      <ExitToAppIcon onClick={doLogout} />
    </>
  );
}
