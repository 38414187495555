import React, { Fragment } from "react";
import { AuthProvider, AuthConsumer } from "./contexts/AuthContext";
import { BrowserRouter, Routes, Route } from "react-router-dom";


import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import { SplashScreen } from "./pages/SplashScreen";

import { StatusProvider, StatusConsumer } from "./contexts/StatusContext";
import { ClockProvider } from "./contexts/ClockContext";

function App() {
  return (
    <ClockProvider>
      <StatusProvider>
        <StatusConsumer>
          {(status) =>
            !status.isOnline ? (
              <SplashScreen noInternet={true} />
            ) : (
              <AuthProvider>
                <AuthConsumer>
                  {(auth) =>
                    !auth.isInitialized ? (
                      <SplashScreen noInternet={false} />
                    ) : (
                      <BrowserRouter>
                        <Routes>
                          {auth.isAuthenticated ? (
                            <Fragment>
                              <Route path="/" element={<Dashboard />} />
                              <Route path="/:id" element={<Dashboard />} />
                            </Fragment>
                          ) : (
                            <Fragment>
                              <Route path="/" element={<Login />} />
                              <Route path="/:id" element={<Login />} />
                            </Fragment>
                          )}
                          <Route path="/login/" element={<Login />} />
                          <Route path="/login/:id" element={<Login />} />
                        </Routes>
                      </BrowserRouter>
                    )
                  }
                </AuthConsumer>
              </AuthProvider>
            )}
        </StatusConsumer>
      </StatusProvider>
    </ClockProvider >
  );
}
export default App;

//<PrivateRouteContainer exact path="/" component={Dashboard} />
