import { createContext, useContext, useState, useEffect } from "react";
import Moment from "moment";
import _ from "lodash";

import { useCLOCK } from "./ClockContext";
import { useAuth } from "./AuthContext";
import { database } from "../utils/firebase";
import { query, where, orderBy, limit, onSnapshot } from "firebase/firestore";

const EventsContext = createContext();

export function useEvents() {
  return useContext(EventsContext);
}
export function EventsProvider({ children }) {
  const { display } = useAuth();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const [events, setEvents] = useState([]);
  const [eventsRaw, setEventsRaw] = useState([]);

  const { minutes } = useCLOCK();

  useEffect(() => {
    const nowData = new Date();
    const now = Moment(
      new Date(
        nowData.getFullYear(),
        nowData.getMonth(),
        nowData.getDate(),
        0,
        0,
        0
      )
    ).format("x");

    const unsubscribe =
      onSnapshot(
        query(database.events,
          where("spaceId", "==", display.typeSettings.spaceId),
          where("endTime", ">=", database.setTimeStampMS(now)),
          orderBy("endTime"),
          limit(20)
        ),
        (snapShot) => {
          const events = [];
          snapShot.forEach((eventDoc) => {
            const docData = eventDoc.data();
            if (!docData.isCancelled)
              events.push(eventDoc.data());
          });
          setLoading(false);
          setEventsRaw(events);
        },
        (err) => {
          console.error(err);
          setError(err);
        }
      );

    return () => unsubscribe();
  }, [display, minutes]);

  useEffect(() => {
    if (eventsRaw.length > 0) {
      const es = filterEvents(eventsRaw);
      setEvents(es);
    } else if (eventsRaw.length === 0) {
      setEvents([]);
    }
  }, [eventsRaw, minutes]);

  const value = {
    error,
    loading,
    events
  };


  return (
    <EventsContext.Provider value={value}>
      {children}
    </EventsContext.Provider>
  );
}

function filterEvents(eventArray) {

  var filteredArray = [];
  var nowDate = Date.now();

  var arrayLength = eventArray.length;
  for (var i = 0; i < arrayLength; i++) {
    var event = eventArray[i];

    if (event.eventId !== null) {
      // Fixa så att endast möten som är idag finns med
      if (isToDay(event, nowDate)) {
        if (filteredArray[event.eventId] !== undefined) {
          // event exists
          var tempEvent = filteredArray[event.eventId];
          if (event.modified >= tempEvent.modified) {
            // we have a winner
            if (
              event.changeType === "deleted" ||
              event.status === "cancelled"
            ) {
              // How to delete
              delete filteredArray[event.eventId];
            } else {
              filteredArray[event.eventId] = event;
            }
          }
        } else if (
          event.changeType !== "deleted" &&
          event.status !== "cancelled"
        ) {

          filteredArray[event.eventId] = event;
        }
      } else {
        delete filteredArray[event.eventId];

      }
    }
  }


  var resultArray = [];
  for (var key in filteredArray) {
    resultArray.push(filteredArray[key]);
  }


  var resultArraySorted = _.orderBy(resultArray, "startTime", "asc");
  return resultArraySorted;
}

function isToDay(event, nowDate) {

  var startDay = Moment.unix(event.startTime.seconds).format("L");
  var nowDay = Moment(nowDate).format("L");

  var startDate = event.startTime.toDate().getTime();
  var endDate = event.endTime.toDate().getTime();


  if (startDate < nowDate && endDate >= nowDate) {
    return true; // Pågående möte
  }


  if (startDate >= nowDate && startDay === nowDay) {
    return true; // Möte som startar idag
  }

  // Möte som startar nästa dag och framåt
  // Möte som har slutat innan nu
  // Möten som ligger tidigare än idag

  return false;
}
