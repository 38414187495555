import React, { Fragment, useState, useEffect } from "react";
import {
    Grid
} from "@mui/material";
import Area from "./Area";

export default function AreaCheckins(props) {
    const { checkins } = props;
    const [areas, setAreas] = useState([]);

    useEffect(() => {
        const listAreas = [];
        checkins.forEach((check) => {
            const area = check.area;
            if (!inArea(check.areaId, listAreas)) {
                listAreas.push(area)
            }
        });

        setAreas(listAreas);
    }, [checkins])

    const inArea = (areaId, areas) => {
        const area = areas.find((item) => item.id === areaId)
        if (area)
            return true
        else
            return false;
    }

    return (
        <Fragment>

            {areas && areas.map((area) => {
                return (
                    <Grid item lg={6} md={6} sm={6} xs={6} >
                        <Area key={area.id} area={area} checkins={checkins.filter((checkin) => checkin.areaId === area.id)} />
                    </Grid>
                )
            })}

        </Fragment>
    );
}
