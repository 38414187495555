import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";

import { Typography } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useAuth } from "../../contexts/AuthContext";
import { useCLOCK } from "../../contexts/ClockContext";
import { database } from "../../utils/firebase";
import { doUpdateDevice } from "../../utils/util.displays";
import { useSTATUS, SCREENSTATUS } from "../../contexts/StatusContext";

export default function BrainCheck() {
    const theme = useTheme();
    const { device, display, addError, removeError, doPong } = useAuth();
    const { minutes } = useCLOCK();
    const { status } = useSTATUS();

    const [refresh, setRefresh] = React.useState();
    const [firstLoad, setFirstLoad] = React.useState(true);

    const [dimension, setDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });


    const xs = useMediaQuery(theme.breakpoints.only("xs"));
    const sm = useMediaQuery(theme.breakpoints.only("sm"));
    const md = useMediaQuery(theme.breakpoints.only("md"));
    const lg = useMediaQuery(theme.breakpoints.only("lg"));
    const xl = useMediaQuery(theme.breakpoints.only("xl"));

    useEffect(() => {
        window.addEventListener("resize", () => {
            setDimension({
                width: window.innerWidth,
                height: window.innerHeight
            });

        })
    }, [window.innerWidth]);

    const getSize = (theme, dimension) => {

        const res = " (" + dimension.width + "x" + dimension.height + ")";

        if (xs) return "XS" + res;
        if (sm) return "SM" + res;
        if (md) return "MD" + res;
        if (lg) return "LG" + res;
        if (xl) return "XL" + res;
    }

    useEffect(() => {
        if (device && display && minutes) {
            doCheck();
        }
    }, [device, display, minutes])

    useEffect(() => {
        if (refresh) {
            var currentStatus = 0;
            switch (status) {
                case SCREENSTATUS.BUSY:
                    currentStatus = 2;
                    break;
                default:
                    currentStatus = 0;
            }
            doPong(currentStatus);
            const now = moment().subtract(15, "minutes");
            const refresh = moment(device.refresh.toDate())
            if (now.isAfter(refresh) && process.env.NODE_ENV !== "development") {
                addError("refresh", "not updating", moment().toLocaleString())
            } else {
                removeError("refresh")
            }
        }
    }, [refresh])


    const doCheck = () => {
        const newRefresh = device?.refresh.toDate().getTime();
        if (newRefresh !== refresh) {
            setRefresh(newRefresh)
        }

        // Check refresh
        if (!display || display.id === "") {
            window.location.href = "/login";
            addError("display", "lost display connection", moment().toLocaleString())
        } else {
            removeError("display")
        }

        // Check refresh
        if (!device || device.id === "") {
            console.log("device", device);
            addError("device", "lost device connection", moment().toLocaleString())
        } else {
            removeError("device")
        }
    }

    const doAction = async () => {
        var data = {};
        switch (device.action) {
            case "reload":
                data = {
                    modified: database.getCurrentTimestamp(),
                    action: ""
                };
                await doUpdateDevice(device.id, data)
                doReload();
                break;
            case "disableui":
                data = {
                    modified: database.getCurrentTimestamp(),
                    action: ""
                };
                await doUpdateDevice(device.id, data)
                disabledUI();
                break;
            case "enableui":
                data = {
                    modified: database.getCurrentTimestamp(),
                    action: ""
                };
                await doUpdateDevice(device.id, data)
                enableUI();
                break;
            case "restartunit":
                data = {
                    modified: database.getCurrentTimestamp(),
                    action: ""
                };
                await doUpdateDevice(device.id, data)
                restartUnit();
                break;

        }
    }

    const disabledUI = () => {
        console.log("disabledUI received");
        try {
            SpacemerDisplay.disableUI(); // eslint-disable-line no-eval
        } catch (err) {
            console.err(err)
        }
    };

    const enableUI = () => {
        console.log("enableUI received");
        try {
            SpacemerDisplay.enableUI(); // eslint-disable-line no-eval
        } catch (err) {
            console.err(err)
        }
    };

    const restartUnit = () => {
        console.log("restartUnit received");
        try {
            SpacemerDisplay.restartUnit(); // eslint-disable-line no-eval
        } catch (err) {
            console.err(err)
        }
    };

    useEffect(() => {
        if (device && device.action !== "") {
            doAction();
        }

    }, [device])

    const doReload = async () => {
        window.location.reload();
    };
    return (
        <Fragment>
            {
                display && display.deviceSettings.debug && (
                    <Typography
                        sx={{
                            fontSize: {
                                xs: "1rem",
                                sm: "1.5rem",
                                lg: "1.5rem"

                            }
                        }}
                        noWrap={true}>
                        {dimension && getSize(theme, dimension)}
                    </Typography>

                )
            }
        </Fragment>
    );
}
