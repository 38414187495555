import React from "react";

import { Typography, Box, Grid } from '@mui/material';
import { useAuth } from "../../contexts/AuthContext";
import { twoDigit } from "../../hooks/useTime";
import { useCLOCK } from "../../contexts/ClockContext";

export default function Clock(props) {
  const { big, text, mobile } = props;
  const { display } = useAuth();
  const { minutes, hours } = useCLOCK();

  if (big) {
    return (
      <Box className="infoBoxInner"
        style={{
          marginLeft: "40px",
          marginRight: "40px"
        }} >
        <Typography
          sx={{
            fontSize: {
              xs: "5rem",
              sm: "5rem",
              md: "10rem",
              lg: "10rem",
              xl: "12rem"
            }
          }}
          noWrap={true} >
          {twoDigit(hours)}:{twoDigit(minutes)}
        </Typography>
        <Typography
          sx={{
            fontSize: {
              xs: "1.5rem",
              sm: "1.5rem",
              lg: "2.0rem",
              xl: "3.5rm"
            }
          }}
          noWrap={true}>
          {text}
        </Typography>
        <br></br>
      </ Box>
    )
  } else if (mobile) {
    return (
      <Grid container item spacing={0}
        direction="row"
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{
          height: '100%',
        }} >
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} align="center">
          <Typography
            sx={{
              color: "white",
              fontWeight: "bold",
              marginRight: "20px",
              fontSize: {
                xs: "2rem",
                sm: "2rem",
                md: "3rem",
                lg: "4.5rem",
                xl: "4rem"
              }
            }}
            noWrap={true} >
            {twoDigit(hours)}:{twoDigit(minutes)}
          </Typography>
        </Grid >
      </Grid>)
  } else {
    return (
      <Grid container item spacing={0}
        direction="row"
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{
          height: '100%',
          borderRight: "3px solid white"
        }} >
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} align="right">
          <Typography
            sx={{
              color: "white",
              fontWeight: "bold",
              marginRight: "20px",
              fontSize: {
                xs: "2rem",
                sm: "2rem",
                md: "3rem",
                lg: "4rem",
                xl: "4rem"
              }
            }}
            noWrap={true} >
            {twoDigit(hours)}:{twoDigit(minutes)}
          </Typography>
        </Grid >
      </Grid>
    );
  }
}
