import React, { } from "react";
import moment from "moment";
import {
    Grid,
    Typography
} from "@mui/material";
import { useAuth } from "../../contexts/AuthContext";
import Settings from "./Settings";
import BrainCheck from "./BrainCheck";

export default function SimpleBottomNavigation() {
    const { display, device, errors } = useAuth();
    return (
        <Grid container spacing={0} justifyContent="center" align="center" >
            <Grid item lg={3} md={3} xs={3} align="left" >
                <BrainCheck />
            </Grid>
            <Grid item lg={7} md={7} xs={7} align="center" >
                <Typography
                    sx={{
                        fontSize: {
                            xs: "1rem",
                            sm: "1.5rem",
                            lg: "1.5rem"

                        }
                    }}
                    noWrap={true}>
                    {display && display.deviceSettings.showRefresh ? moment.unix(device.refresh.seconds).format("YYYY-MM-DD HH:mm") : ""}
                </Typography>
            </Grid>
            <Grid item lg={2} md={2} xs={2} align="center" >
                <Typography
                    sx={{
                        fontSize: {
                            xs: "1rem",
                            sm: "1.5rem",
                            lg: "1.5rem"

                        }
                    }}
                    noWrap={true}>
                    {display && display.deviceSettings.showCode ? device.code : ""}
                </Typography>
                {display && display.displaySettings.showLogout && (
                    <Settings />
                )}

            </Grid>
            <Grid item lg={12} md={12} xs={12} align="center" >
                {display && display.deviceSettings.debug && errors.map((error) => {
                    return (

                        <Typography
                            key={error.key}
                            color="error"
                            sx={{
                                fontSize: {
                                    xs: "0.8rem",
                                    sm: "0.8rem",
                                    lg: "1.0rem"

                                }
                            }}
                            noWrap={true}>
                            {error.key} - {error.message}: {error.time}
                        </Typography>
                    )
                })}

            </Grid>
        </Grid>

    );
}