import React, { Fragment } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Box } from '@mui/material';
import { QRCode } from 'react-qrcode-logo';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function AreaCheckin() {
  const { display } = useAuth();
  const theme = useTheme();

  const getQrSize = () => {
    if (useMediaQuery(theme.breakpoints.down("sm"))) {
      return "90";
    } else {
      return "120"
    }

  }

  return (
    <Fragment>
      {display && display.typeSettings.isAllowCheckin && display.typeSettings.areaId !== "" && (
        <Box
          className="iconButton"
          style={{ backgroundColor: "white" }}
          sx={{
            borderRadius: "10%",
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
            position: 'absolute',
            bottom: {
              xs: 25,
              sm: 25,
              md: 25
            },
            right: {
              xs: 25,
              sm: 25,
              md: 25
            },
            height: {
              xs: "100px",
              sm: "130px",
              md: "130px"
            },
            width: {
              xs: "100px",
              sm: "130px",
              md: "130px"
            },
          }}
        >
          <Box
            width={"100%"}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ paddingTop: "5px" }}>
            <QRCode
              size={getQrSize()} // 120 MD
              ecLevel={"H"}
              quietZone={0}
              removeQrCodeBehindLogo={true}
              logoPadding={3}
              qrStyle={"dots"}
              logoImage={"/assets/img/scan-1.png"}
              value={
                "https://portal.spacemer.com/?area=" + display.typeSettings.areaId
              } />
          </Box>

        </Box>
      )
      }
    </Fragment >
  );
}

//className="Absolute-Center"