
import React from "react";
import {
    Grid,
    Typography
} from "@mui/material";
import { QRCode } from 'react-qrcode-logo';
import { useAuth } from "../../contexts/AuthContext";

export default function WelcomeScreen() {
    const { user } = useAuth();
    return (
        <Grid item container spacing={0}
            direction="row"
            justifyContent="center"
            alignItems="center">
            <Grid lg={6} md={6} sm={6} xs={6} sx={{ backgroundColor: "" }}>
                <Typography
                    align="left"
                    sx={{
                        paddingLeft: "100px",
                        fontWeight: "bold",
                        fontSize: "5rem"
                    }} >
                    Welcome to
                </Typography><br></br>
                <Typography
                    align="left"
                    sx={{
                        paddingLeft: "100px",
                        fontWeight: "bold",
                        fontSize: "4rem"
                    }} >
                    {user?.company?.name}
                </Typography>
            </Grid>
            <Grid lg={6} md={6} sm={6} xs={6}
                sx={{ backgroundColor: "" }}
                align="center">
                <div style={{
                    marginTop: "100px",
                    backgroundColor: "white",
                    paddingTop: "50px",
                    paddingBottom: "48px",
                    borderRadius: "10px",
                    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                    textAlign: "center",
                    marginLeft: "20%",
                    marginRight: "20%"
                }}>
                    <Typography
                        align="center"
                        color="textSecondary"
                        sx={{
                            paddingBottom: "20px",
                            fontWeight: "bold",
                            fontSize: "2rem"
                        }} >
                        Scan with your phone
                    </Typography>
                    <QRCode
                        size={300}
                        ecLevel={"H"}
                        quietZone={0}
                        removeQrCodeBehindLogo={true}
                        logoPadding={5}
                        qrStyle={"dots"}
                        logoHeight={62}
                        logoImage={"/assets/img/logo-black-full-stand-small.png"}
                        value={
                            "https://portal.spacemer.com/?area="
                        } /> <br></br>
                    <Typography
                        align="center"
                        color="textSecondary"
                        sx={{
                            paddingTop: "20px",
                            fontWeight: "bold",
                            fontSize: "1.5rem"
                        }} >
                        or go to guest.spacemer.com
                    </Typography>
                </div>

            </Grid >
        </Grid>
    );
}
