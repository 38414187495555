import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { createRoot } from 'react-dom/client';
import App from "./App";
import ErrorBoundary from "./components/ErrorBoundary";


import "./assets/css/spacemerdisplay2.css";

import { createTheme } from "./theme/";

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<React.StrictMode>
  <ThemeProvider
    theme={createTheme({
      direction: "ltr",
      responsiveFontSizes: false,
      mode: "dark",
    })}
  >
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </ThemeProvider>
</React.StrictMode>);
