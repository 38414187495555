import React, { createContext, useContext, useState } from "react";
import { useTime } from "../hooks/useTime";
const ClockContext = createContext();

export function useCLOCK() {
  return useContext(ClockContext);
}

export function ClockProvider({ children }) {
  const { minutes, hours } = useTime(10000);
  const [big, setBig] = useState(false)

  const value = {
    minutes,
    hours,
    big,
    setBig
  };

  return <ClockContext.Provider value={value}>{children}</ClockContext.Provider>;
}
