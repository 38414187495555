import React, { useState, useEffect, Fragment } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { Toaster } from "react-hot-toast";
import * as Yup from "yup";
import {
  Box,
  Card,
  Container,
  Typography,
  TextField,
  Button,
  FormHelperText
} from "@mui/material";
import toast from "react-hot-toast";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import QrReader from "modern-react-qr-reader";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import Logo from "../assets/img/logo-white-full.png";
import LogoLoader from "../components/main/LogoLoader";

import { QRCode } from 'react-qrcode-logo';
import { database } from "../utils/firebase";
import { addDoc, doc, onSnapshot } from "firebase/firestore";
import { setColor } from "../utils/util.led";

export default function LoginNew() {
  const { loginWithPinKeyless, logout, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [listen, setListen] = useState(false);
  const [deviceId, setDeviceId] = useState();
  const [displayId, setDisplayId] = useState(id);
  const [device, setDevice] = useState();
  const [code, setCode] = useState();
  const [displayCode, setDisplayCode] = useState();
  const [qrScan, setQrScan] = useState(false);
  const [manualCode, setManualCode] = useState(false);

  const formik = useFormik({
    initialValues: {
      displaycode: "",
      submit: null,
    },
    validationSchema: Yup.object({
      displaycode: Yup.string().required("Display Code is required"),
    }),
    onSubmit: async (values, helpers) => {
      try {
        setDisplayCode(values.displaycode)
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const doReload = async () => {
    window.location.reload();
  };


  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    async function doUseEffect() {
      await logout("Login");
    }
    doUseEffect();
  }, []);

  useEffect(() => {
    if (pageLoaded) {
      // Lets start the process
      setupDeviceListen()
    }
  }, [pageLoaded]);

  const setupDeviceListen = async () => {
    var code = Math.floor(Math.random() * 90000) + 10000;
    const data = {
      approved: false,
      code: "" + code,
      ident: [displayId ? displayId : displayCode],
      deviceId: displayId ? displayId : "",
      modified: database.getCurrentTimestamp(),
      spaceId: "",
      areaId: "",
      pinCode: "",
      type: "display",
      version: 0,
      refresh: "",
      action: "",
      companyId: "",
      companyRef: "",
      areaRef: "",
      areaName: "",
      localIP: "",
      data: ""


    }

    const res = await addDoc(database.devices, data);
    const deviceId = res.id;
    setDeviceId(deviceId);
    setListen(true);
    setCode(code);
  }

  const setupDeviceCode = async () => {
    var code = Math.floor(Math.random() * 90000) + 10000;
    const data = {
      approved: false,
      code: "" + code,
      ident: [],
      deviceId: "",
      modified: database.getCurrentTimestamp(),
      spaceId: "",
      areaId: "",
      pinCode: "",
      type: "display",
      version: 0,
      refresh: "",
      action: "",
      companyId: "",
      companyRef: "",
      areaRef: "",
      areaName: "",
      localIP: "",
      data: ""
    }

    const res = await addDoc(database.devices, data);
    const deviceId = res.id;
    setDeviceId(deviceId);
    setListen(true);
    setCode(code);
  }

  useEffect(() => {
    if (device && device.code &&
      device.code !== "" && device.pinCode &&
      device.pinCode !== "") {
      doLoginFromDevice(device);
    } else if (device && (!device.code || device.code === "")) {
      toast.error("Looks like you removed the connection! Refresh to restart...");
      setListen(false);
      setDeviceId(null);
      setCode("");
    }

  }, [device]);

  const doLoginFromDevice = async (device) => {
    await loginWithPinKeyless(device.deviceId, device.pinCode, deviceId);
  }

  useEffect(() => {
    if (listen && deviceId && deviceId !== "") {
      const displayRef = doc(database.devices, deviceId);
      const unsubscribe = onSnapshot(displayRef,
        async (docSnapshot) => {
          const deviceData = database.formatDoc(docSnapshot);
          setDevice(deviceData);
        },
        (err) => {
          console.error(err);
        }
      );
      return () => {
        unsubscribe();
      };
    }
  }, [listen, deviceId]);


  useEffect(() => {
    if ((displayCode || displayId) && !pageLoaded) {
      // Lets start the process
      setPageLoaded(true);
    }
  }, [displayId, displayCode]);

  useEffect(() => {
    if (id) {
      // Lets start the process
      setDisplayId(id);
    }
  }, [id]);

  useEffect(() => {
    setColor("blue", false)
    setColor("blue", true)
  }, []);

  const noScan = (err) => {
    console.error(err);
    setQrScan(false);
  };

  const startCodeTime = () => {
    const timer = setTimeout(() => {
      if (!id && !displayId && !manualCode) {
        setupDeviceCode();
      }
    }, 2000);
    return timer;
  }

  useEffect(() => {
    setCode(undefined)
    var timer = () => { }
    if (!manualCode) {
      timer = startCodeTime();
    }
    return () => { if (timer) clearTimeout(timer) };
  }, [manualCode]);


  const scanSuccess = (data) => {
    if (data) {
      try {
        setQrScan(false);
        var newData = data;
        var displayId = newData.replace("https://display.spacemer.com/", "");
        setDisplayId(displayId)
      } catch (err) { }
    }
  };

  return (
    <form noValidate onSubmit={formik.handleSubmit}>
      <Toaster position="top-center" />
      <Box
        component="main"
        sx={{
          backgroundColor: "rgb(11, 15, 25)",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            py: {
              xs: "60px",
              md: "120px",
            },
          }}
        >
          <Card elevation={16} sx={{ p: 4 }} className="login-box-background">
            {(displayId || displayCode) && !qrScan && (
              <Fragment>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="login-logo"
                    onClick={() => {
                      doReload();
                    }}
                  >
                    <img alt="Logo" height="60" width="305" src={Logo} />
                  </div>

                  <Typography variant="h4">Keyless Login</Typography>
                  <Typography
                    color="textSecondary"
                    sx={{ mt: 2 }}
                    variant="body1"
                    align="center"
                  >
                    Scan QR Code to go to admin and approve.<br></br>
                    Process will automatically continue on approve
                  </Typography>
                  <br></br>
                  <br></br>
                  {code && code !== "" && (
                    <Fragment>
                      <QRCode
                        size={200}
                        ecLevel={"H"}
                        quietZone={0}
                        removeQrCodeBehindLogo={true}
                        logoPadding={5}
                        qrStyle={"dots"}
                        logoHeight={49}
                        logoImage={"/assets/img/logo-black-full-stand.png"}
                        value={
                          "https://admin.spacemer.com/device/hardware?code=" + code
                        } />
                      <Typography
                        color="textSecondary"
                        sx={{ mt: 2 }}
                        variant="body2"
                        align="center"
                      >
                        (Only available for 5 minutes!)
                      </Typography>
                    </Fragment>
                  )}
                  {!code && code !== "" && (
                    <Fragment>
                      <LogoLoader width="50" height="50" /><br></br>
                      <Typography color="textSecondary" variant="h4"><i>Generating QR Code...</i></Typography>
                    </Fragment>
                  )}
                  {!code && code === "" && (
                    <Fragment>
                      <Typography variant="h5">
                        <i>Refresh to restart!</i>
                      </Typography>
                      <br></br>
                      <AutorenewIcon
                        style={{ color: "textSecondary" }}
                        sx={{ fontSize: 50 }}
                        onClick={() => {
                          doReload();
                        }} />
                    </Fragment>

                  )}
                  <br></br>
                  <Typography variant="h4">{code ? code : ""}</Typography>
                  <br></br>
                  <Button onClick={() => {
                    doReload();
                  }}>Cancel</Button>
                </Box>

              </Fragment>
            )}
            {(!displayId && !pageLoaded) && !qrScan && manualCode && (
              <Fragment>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="login-logo"
                    onClick={() => {
                      doReload();
                    }}
                  >
                    <img alt="Logo" height="60" width="305" src={Logo} />
                  </div>

                  <Typography variant="h4">Setup Display</Typography>
                  <Typography
                    color="textSecondary"
                    sx={{ mt: 2 }}
                    variant="body2"
                  >
                    Enter Display Code to start your journey
                    <br></br>
                    (Note, not do be confused with Pin Code)
                  </Typography>
                </Box>
                <Box
                  sx={{
                    flexGrow: 1,
                    mt: 3,
                  }}
                >
                  <TextField
                    error={Boolean(
                      formik.touched.displaycode && formik.errors.displaycode
                    )}
                    fullWidth
                    helperText={formik.touched.displaycode && formik.errors.displaycode}
                    label="Display Code"
                    name="displaycode"
                    type="text"
                    disabled={formik.isSubmitting}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.displaycode}
                  />
                  {formik.errors.submit && (
                    <Box sx={{ mt: 3 }}>
                      <FormHelperText error>
                        {formik.errors.submit}
                      </FormHelperText>
                    </Box>
                  )}
                  <Box sx={{ mt: 2 }}>
                    <Button
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      disabled={formik.isSubmitting}
                    >
                      Continue to approval
                    </Button>
                  </Box>
                  {(!id || id === "") && (
                    <Box sx={{ mt: 2 }}>
                      <Button
                        fullWidth
                        size="large"
                        onClick={() => {
                          setQrScan(true);
                        }}
                        variant="outlined"
                        disabled={formik.isSubmitting}
                        style={{ padding: "5px" }}
                      >
                        <QrCodeScannerIcon style={{ margin: "5px" }} />
                      </Button>
                    </Box>
                  )}

                </Box>
                <Box
                  sx={{
                    paddingTop: "20px",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Button onClick={() => { setManualCode(false) }}>Use Keyless Login</Button>
                </Box>
              </Fragment>
            )}
            {(!displayId && !pageLoaded) && !qrScan && !manualCode && (
              <Fragment>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="login-logo"
                    onClick={() => {
                      doReload();
                    }}
                  >
                    <img alt="Logo" height="60" width="305" src={Logo} />
                  </div>

                  <Typography variant="h4">Keyless Login</Typography>
                  <Typography
                    color="textSecondary"
                    sx={{ mt: 2 }}
                    variant="body1"
                    align="center"
                  >
                    Scan QR Code to go to admin to connect!<br></br>
                    After connecting you will be able to approve the connection.<br></br>
                    Process will automatically continue on approve
                  </Typography>
                  <br></br>
                  <br></br>
                  {code && code !== "" && (
                    <Fragment>
                      <QRCode
                        size={200}
                        ecLevel={"H"}
                        quietZone={0}
                        removeQrCodeBehindLogo={true}
                        logoPadding={5}
                        qrStyle={"dots"}
                        logoHeight={49}
                        logoImage={"/assets/img/logo-black-full-stand.png"}
                        value={
                          "https://admin.spacemer.com/device/hardware?code=" + code
                        } />
                      <Typography
                        color="textSecondary"
                        sx={{ mt: 2 }}
                        variant="body2"
                        align="center"
                      >
                        (Only available for 5 minutes!)
                      </Typography>
                    </Fragment>
                  )}
                  {!code && code !== "" && (
                    <Fragment>
                      <LogoLoader width="50" height="50" /><br></br>
                      <Typography color="textSecondary" variant="h4"><i>Generating QR Code...</i></Typography>
                    </Fragment>
                  )}
                  {!code && code === "" && (
                    <Fragment>
                      <Typography variant="h5">
                        <i>Refresh to restart!</i>
                      </Typography>
                      <br></br>
                      <AutorenewIcon
                        style={{ color: "textSecondary" }}
                        sx={{ fontSize: 50 }}
                        onClick={() => {
                          doReload();
                        }} />
                    </Fragment>

                  )}
                  <br></br>
                  <Typography variant="h4">{code ? code : ""}</Typography>

                </Box>

              </Fragment>
            )}
            {qrScan && (
              <Fragment>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    color="textSecondary"
                    sx={{ mt: 2 }}
                    variant="h5"
                  >
                    Scan QR Code
                  </Typography>
                </Box>
                <Box
                  sx={{
                    flexGrow: 1,
                    mt: 3,
                  }}
                >
                  <QrReader
                    delay={300}
                    onError={noScan}
                    onScan={scanSuccess}
                    style={{ width: "100%" }}
                  />
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Button
                    fullWidth
                    size="large"
                    onClick={() => {
                      setQrScan(false);
                    }}
                    variant="outlined"
                    disabled={formik.isSubmitting}
                  >
                    Cancel
                  </Button>
                </Box>
              </Fragment>
            )}

          </Card>
        </Container>
      </Box>

    </form>
  );
}

/**
 *                   
                  <br></br>
                  <Button onClick={() => { setManualCode(true) }}>Add Display Code manually</Button>
 */
