import React, { useContext, useState, useEffect } from "react";
import { auth, database, functions } from "../utils/firebase";
import { doUpdateDevice } from "../utils/util.displays";
import { signInWithCustomToken, onAuthStateChanged, signOut } from "firebase/auth";
import { updateDoc, doc, getDoc, onSnapshot } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

const defaultUser = {
  id: "",
  display: "",
  displayId: "",
  displayRef: "",
  company: "",
  companyId: "",
  companyRef: "",
  deviceId: "",
};

export function AuthProvider({ children }) {
  const [user, setUser] = useState(defaultUser);

  const [firebaseUser, setFirebaseUser] = useState(null);

  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const [displayId, setDisplayId] = useState("");
  const [display, setDisplay] = useState(null);

  const [deviceId, setDeviceId] = useState("");
  const [device, setDevice] = useState(null);

  const [errors, setErrors] = useState([]);

  const version = "1.0";

  async function loginWithPinKeyless(displayId, pinCode, deviceId) {
    var authenticateCall = httpsCallable(functions, "authenticateCall");
    const data = {
      deviceId: displayId,
      pinCode: pinCode
    };
    var result = await authenticateCall(data);
    setDisplayId(result.data.id.deviceId);
    setDeviceId(deviceId);

    const res = signInWithCustomToken(auth, result.data.customToken);
    // Update Device key
    const updateSuccess = {
      modified: database.getCurrentTimestamp(),
      pinCode: "",
    };
    await doUpdateDevice(deviceId, updateSuccess)
    return res;
  }

  async function logout(source) {
    await signOut(auth);
    localStorage.clear();
    return true;
  }

  const addError = (key, message, time) => {
    setErrors(oldArray => {

      var newArray = oldArray.filter((error) => error.key !== key);
      newArray.push({ key: key, message: message, time: time });

      return newArray;

    })
  }

  const removeError = (key) => {
    setErrors(oldArray => {

      var newArray = oldArray.filter((error) => error.key !== key);

      return newArray;

    })
  }

  const getFullUser = async (firebaseUser, displayId, deviceId) => {
    var fullUser = "";
    try {
      if (firebaseUser) {
        if (displayId !== "") {
          const displayRef = doc(database.displays, displayId);
          var displayDoc = await getDoc(displayRef);
          if (displayDoc.exists()) {
            var data = displayDoc.data();
            setDisplay(database.formatDoc(displayDoc));

            var companyRef = await getDoc(data.companyRef);
            var company = companyRef.data();

            fullUser = {
              id: firebaseUser.uid,
              company: company,
              companyId: data.companyId,
              companyRef: companyRef
            };
          }
        } else {
          console.error("No display found", displayId)
          fullUser = defaultUser;
        }

        if (deviceId !== "") {
          const deviceRef = doc(database.devices, deviceId);
          var deviceDoc = await getDoc(deviceRef);
          if (deviceDoc.exists()) {
            setDevice(database.formatDoc(deviceDoc))
          } else {
            // No device found
            console.error("No device found", deviceId)
            fullUser = defaultUser;
          }
        } else {
          fullUser = defaultUser;
        }
      } else {
        // No user
        fullUser = defaultUser;
      }
    } catch (err) {
      console.error(err)
      fullUser = defaultUser;
    }
    return fullUser;
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const displayId = loadDisplayID();
        const deviceId = loadDeviceID();
        const fullUser = await getFullUser(user, displayId, deviceId);
        if (fullUser !== "") {
          setUser(fullUser);
          setFirebaseUser(user);
          setIsAuthenticated(true);
          setIsInitialized(true);
        } else {
          setUser(defaultUser);
          setFirebaseUser(null);
          setIsAuthenticated(false);
          setIsInitialized(true);
        }
      } else {
        setUser(defaultUser);
        setFirebaseUser(null);
        setIsAuthenticated(false);
        setIsInitialized(true);
      }
    });

    return unsubscribe;
  }, []);

  const loadDeviceID = () => {
    const deviceId = localStorage.getItem("deviceId");
    if (deviceId) {
      setDeviceId(deviceId);
    }

    return deviceId;
  };

  const loadDisplayID = () => {
    const displayId = localStorage.getItem("displayId");
    if (displayId) {
      setDisplayId(displayId);
    }
    return displayId;
  };

  const doPong = async (status) => {
    const deviceDoc = await getDoc(doc(database.devices, device.id));
    if (deviceDoc.exists()) {
      const updateData = {
        modified: database.getCurrentTimestamp(),
        version: version,
        data: status,
      }
      await updateDoc(doc(database.devices, device.id), updateData);
      console.log("Ping Update")
    }
  }

  useEffect(() => {
    loadDisplayID();
    loadDeviceID();
  }, []);

  useEffect(() => {
    if (displayId !== "") {
      const displayRef = doc(database.displays, displayId);
      const unsubscribe = onSnapshot(displayRef,
        async (docSnapshot) => {
          const displayData = database.formatDoc(docSnapshot);
          if (displayData && (!displayData.pinCode || displayData.pinCode === "")) {
            await logout("AuthContext-displaydata");
          } else {
            setDisplay(displayData);
          }
        },
        (err) => {
          console.error(err);
        }
      );
      return () => {
        unsubscribe();
      };
    }
  }, [user]);

  useEffect(() => {
    if (deviceId && deviceId !== "") {
      const deviceRef = doc(database.devices, deviceId);
      const unsubscribe = onSnapshot(deviceRef,
        async (docSnapshot) => {
          const deviceData = database.formatDoc(docSnapshot);
          if (deviceData && (!deviceData.code || deviceData.code === "")) {
            await logout("AuthContext-devicedata");
          } else {
            if (deviceData.deviceId !== displayId) {
              setDisplayId(deviceData.deviceId);
              window.location.reload();
            }
            setDevice(deviceData);
          }
        },
        (err) => {
          console.error(err);
        }
      );
      return () => {
        unsubscribe();
      };
    }
  }, [deviceId]);

  useEffect(() => {
    if (displayId && displayId !== "") {
      localStorage.setItem("displayId", displayId);
    }
  }, [displayId]);

  useEffect(() => {
    if (deviceId && deviceId !== "") {
      localStorage.setItem("deviceId", deviceId);
    }
  }, [deviceId]);

  const value = {
    isInitialized,
    user,
    display,
    device,
    isAuthenticated,
    loginWithPinKeyless,
    logout,
    errors,
    addError,
    removeError,
    doPong
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export const AuthConsumer = AuthContext.Consumer;
