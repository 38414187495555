import React from "react";
import { getInitials } from "../../utils/get-initials";
import {
    Box,
    Avatar,
    Typography
} from "@mui/material";
import Moment from "react-moment";

export default function Checkin(props) {
    const { checkin } = props;
    const space = checkin.space;
    const area = checkin.area;

    const getColor = (space) => {
        //const number = randomIntFromInterval(0, 3)
        switch (space.oPresence) {
            case "available":
            case "availableidle":
                return "green";
            case "busy":
            case "busyidle":
            case "donotdisturb":
                return "#B22222";
            case "berightback":
                return "#FFD700";
            default:
                return undefined;

        }

    }

    const randomIntFromInterval = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    const getPresence = (space) => {
        const color = getColor(space);
        if (color)
            return `5px solid ${color}`
        else
            return "0px"
    }


    return (

        <Box
            className="infoBoxInner"
            sx={{
                margin: {
                    xs: "5px",
                    sm: "5px",
                    md: "15px",
                    lg: "15px",
                    xl: "15px"

                }
            }}>
            <Box
                sx={{
                    alignItems: "center",
                    display: "flex",
                    padding: "20px"
                }}
            >
                <Avatar
                    src={space.avatar}
                    sx={{
                        height: {
                            xs: 50,
                            sm: 60,
                            lg: 80,
                            xl: 80
                        },
                        width: {
                            xs: 50,
                            sm: 60,
                            lg: 80,
                            xl: 80
                        },
                    }}>
                    {getInitials(space.name)}
                </Avatar>
                <Box sx={{ marginLeft: "20px" }}>
                    <Typography
                        sx={{
                            fontSize: {
                                xs: "1.3rem",
                                sm: "1.3rem",
                                md: "1.5rm",
                                lg: "1.6rem",
                                xl: "3.0rm"
                            },
                            paddingBottom: "0px"
                        }}>
                        {space.name}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: {
                                xs: "1rem",
                                sm: "1rem",
                                lg: "1.5rem",
                                xl: "2.5rm"
                            },
                            paddingTop: "0px",
                            fontWeight: "bold"
                        }}
                        noWrap={true}>
                        {area.parentAreaName} - {area.name}
                    </Typography>
                    <Typography variant="body2">
                        <Moment format="YYYY-MM-DD" unix>
                            {checkin.startTime.seconds}
                        </Moment>
                    </Typography>
                </Box>
            </Box>

        </Box >
    );
}

/**
 * 
 *   style={{
                        border: getPresence(checkin.space),
                    }}
 */