const moment = require("moment-timezone");

module.exports.getNiceTimeSpanString = (data, timeZone, withDate) => {
  var result = "";
  if (this.isAllDay(data)) {
    result = "";
  } else {
    result =
      " (" +
      this.getNiceTimeString(data.startTime, timeZone) +
      "-" +
      this.getNiceTimeString(data.endTime, timeZone) +
      ")";
  }

  if (withDate) {
    result = this.getNiceDateString(data.startTime, timeZone) + result;
  }

  return result;
};

module.exports.getNiceDateString = (timestamp, timeZone) => {
  var date = this.getMDateZone(timestamp, timeZone);
  return date.format("yyyy-MM-DD");
};

module.exports.getNiceDateStringNoZone = (timestamp) => {
  var date = this.getMDateNoZone(timestamp);
  return date.format("yyyy-MM-DD");
};

module.exports.getNiceTimeString = (timestamp, timeZone) => {
  var date = this.getMDateZone(timestamp, timeZone);
  return date.format("HH:mm");
};

module.exports.getMDateZone = (timestamp, timeZone) => {
  if (!timeZone || timeZone === "") {
    timeZone = "Europe/Stockholm"
  }
  var date = moment(timestamp.toDate()).tz(timeZone);
  return date;
};

module.exports.getMDateNoZone = (timestamp) => {
  var date = moment(timestamp.toDate());
  return date;
};

module.exports.getToday = (timeZone) => {
  if (!timeZone || timeZone === "") {
    timeZone = "Europe/Stockholm"
  }
  var date = moment(moment().format("yyyy-MM-DD")).tz(timeZone);
  return date;
};

module.exports.getTodayNoTimeZone = () => {
  var date = moment(moment().format("yyyy-MM-DD"));
  return date;
};

module.exports.getNow = (timeZone) => {
  if (!timeZone || timeZone === "") {
    timeZone = "Europe/Stockholm"
  }
  var date = moment().tz(timeZone);
  return date;
};

module.exports.getNowNoTimeZone = () => {
  var date = moment();
  return date;
};

module.exports.dateToMoment = (date) => {
  var date = moment(date);
  return date;
};

module.exports.isNormalTime = (timestamp, timeZone) => {
  if (process.env.FUNCTIONS_EMULATOR) {
    return true;
  } else {
    return false
  }
};

module.exports.isAllDay = (data) => {
  if (data.isAllDay) {
    return true;
  } else if (data.startTime.toMillis() === data.endTime.toMillis()) {
    return true;
  } else {
    return false;
  }
};
