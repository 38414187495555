import React, { Fragment, useEffect, useState } from "react";

import { Button } from '@mui/material';
import { useSTATUS, SCREENSTATUS } from "../../contexts/StatusContext";
import { useCLOCK } from "../../contexts/ClockContext";
import { useAuth } from "../../contexts/AuthContext";
import { setColor } from "../../utils/util.led";


export default function Led() {
    const { status } = useSTATUS();
    const { minutes } = useCLOCK();
    const { display } = useAuth();

    const [android, setAndroid] = useState(true);
    const [web, setWeb] = useState(true);

    useEffect(() => {
        switch (status) {
            case SCREENSTATUS.LOADING:
                updateLed("yellow");
                break;
            case SCREENSTATUS.BUSY:
                updateLed("red");
                break;
            case SCREENSTATUS.FREE:
                updateLed("green");
                break;
            default:
                updateLed("green");
        }
    }, [status, minutes]);

    const updateLed = (color) => {
        console.log("updateLed:", color);
        if (android){
            const androidRes = setColor(color, false);
            if (!androidRes){
                setAndroid(false);
            }
        }
        if (web){
            const webRes = setColor(color, true);
            if (!webRes){
                setWeb(false);
            }
        }

    };

    return (
        <Fragment>
            {display && display.deviceSettings.debug && (
                <Fragment>
                    <Button variant="contained" color="warning" onClick={() => { updateLed("#FFFF00") }}>L</Button>
                    <Button variant="contained" color="error" onClick={() => { updateLed("#FF0000") }}>B</Button>
                    <Button variant="contained" color="success" onClick={() => { updateLed("#008000") }}>A</Button>
                </Fragment>
            )}
        </Fragment>
    );
}
