import React, { Fragment, useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { database } from "../../utils/firebase";
import { useCLOCK } from "../../contexts/ClockContext";
import {
  Box,
  Grid,
} from "@mui/material";
import Moment from "moment";
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import dateUtil from "../../utils/util.date";
import Checkin from "./Checkin";
import AreaCheckins from "./AreaCheckins";
import Pagination from './Pagination';
import LogoLoader from "../main/LogoLoader";
import Clock from "../main/Clock";
import { query, where, getDoc, orderBy, onSnapshot } from "firebase/firestore";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function Checkins(props) {
  const theme = useTheme();
  const { setBig, hours } = useCLOCK();
  const { user, display } = useAuth();

  const [pageIndex, setPageIndex] = useState(0);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true)

  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const sm = useMediaQuery(theme.breakpoints.only("sm"));
  const md = useMediaQuery(theme.breakpoints.only("md"));
  const lg = useMediaQuery(theme.breakpoints.only("lg"));
  const xl = useMediaQuery(theme.breakpoints.only("xl"));

  useEffect(() => {
    if (user && user.companyId !== "") {
      const today = dateUtil.getTodayNoTimeZone();
      const nextDay = Moment(today).add(1, "day");
      var unsubscribe = () => { };
      unsubscribe =
        onSnapshot(
          query(database.checkins,
            where("areaCompanyId", "==", user.companyId),
            where("startTime", ">=", database.setTimeStampMS(today.format("x"))),
            where("startTime", "<", database.setTimeStampMS(nextDay.format("x"))),
            orderBy("startTime")
          ),
          async (snapShot) => {
            const newData = [];
            for (var i = 0; i < snapShot.docs.length; i++) {
              const doc = snapShot.docs[i];
              const data = doc.data();
              const spaceDoc = await getDoc(data.spaceRef);
              const space = { id: spaceDoc.id, ...spaceDoc.data() };
              const areaDoc = await getDoc(data.areaRef);
              var area = { id: areaDoc.id, ...areaDoc.data() };
              newData.push({ id: doc.id, space, area, ...data });
            };
            setData(newData);
            setLoading(false);
          },
          (err) => {
            console.error(err);
          }
        );

      return () => {
        unsubscribe();
      };
    }
  }, [user, hours]);

  useEffect(() => {
    if (data && data.length === 0) {
      setBig(true)
    } else {
      setBig(false);
    }
  }, [data])


  const getPages = (data) => {
    var result = [];
    if (data) {
      const itemsPerPage = getItemCount();
      const length = data.length;
      const pages = Math.ceil(length / itemsPerPage);


      var cursor = 0;
      var currentPage = 0;
      for (var i = 0; i < pages; i++) {
        var items = [];
        for (var j = 0; j < itemsPerPage; j++) {
          const item = data[cursor];
          items.push(item);
          cursor++;
          if (cursor > length) {
            break;
          }
        }
        result.push({
          number: currentPage,
          items: items
        })
        currentPage++;
      }
    }
    return result;
  }

  const getPageCount = (data) => {
    const itemsPerPage = getItemCount();
    const length = data.length;
    const pages = Math.ceil(length / itemsPerPage);
    return pages;
  }
  const changePage = (index) => {
    setPageIndex(index);
  }

  const getItemCount = () => {
    if (xs) return 5;
    if (sm) return 5;
    if (md) return 12;
    if (lg) return 12;
    if (xl) return 15;
  }

  return (
    <Box
      sx={{
        height: "100%",
        paddingTop: {
          xs: "40px",
          sm: "40px",
          md: "0px"
        }
      }}>
      {display && display.typeSettings.group === "area" && (
        <Fragment>
          {data && (
            <AreaCheckins checkins={data} />
          )}
        </Fragment>
      )}
      {display && display.typeSettings.group === "space" && (
        <Fragment>
          {loading && (
            <Grid container item spacing={0}
              direction="row"
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{
                height: '100%',
              }} >
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <LogoLoader />
              </Grid>
            </Grid>
          )}
          {!loading && data.length === 0 && (
            <Grid container item spacing={0}
              direction="row"
              display="flex"
              alignItems="center"
              justifyContent="center"
              style={{
                height: '100%',
              }} >
              <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                <Clock big={true} text={"No check-ins today"} />
              </Grid>
            </Grid>
          )}
          {!loading && data.length > 0 && (
            <Fragment>
              <AutoPlaySwipeableViews
                index={pageIndex} onChangeIndex={changePage} interval={10000}>
                {data && getPages(data).map((page) => {
                  const items = page.items;
                  return (
                    <Grid key={page.number}
                      item container spacing={0}>
                      {items.map((checkin) => {
                        if (checkin) {
                          return (
                            <Grid item key={checkin.id} lg={4} md={4} sm={12} xs={12} align="left" className="infoBox">
                              <Checkin key={checkin.id} checkin={checkin} />
                            </Grid>)
                        }
                      })
                      }
                    </Grid>

                  )
                })}
              </AutoPlaySwipeableViews>
              {getPageCount(data) > 1 && (
                <Grid item lg={12} md={12} sm={12} xs={12} align="center">
                  <Pagination dots={getPageCount(data)} index={pageIndex} onChangeIndex={changePage} />
                </Grid>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </Box >
  );
}