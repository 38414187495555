import { Box, Typography } from "@mui/material";
import LogoLoader from "../components/main/LogoLoader";

export const SplashScreen = (props) => {
  const { noInternet } = props;
  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "neutral.900",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
        left: 0,
        p: 3,
        position: "fixed",
        top: 0,
        width: "100vw",
        zIndex: 2000,
      }}
    >
      <LogoLoader />
      {noInternet && (
        <Typography
          color="white"
          fontSize="1.5em"
          sx={{ paddingTop: "50px" }}
        >
          We have sadly lost<br></br>connection to internet...
        </Typography>
      )
      }
    </Box >)
};
