import { useState, useEffect } from "react";

export function useTime(interval) {
  const [time, setTime] = useState(new Date());
  const [hours, setHours] = useState();
  const [minutes, setMinutes] = useState();

  useEffect(() => {
    const id = setInterval(() => {
        setTime(new Date());
    }, interval);
    return () => clearInterval(id);
  }, [interval]);

  useEffect(() => {
    if (Object.prototype.toString.call(time) === '[object Date]') {
      if (time.getHours() !== hours) {

        setHours(time.getHours());
      }
      if (time.getMinutes() !== minutes) {

        setMinutes(time.getMinutes());
      }
    }
  }, [time]);

  return { hours, minutes };
}

export function twoDigit(str){
  var resStr = "" + str;
  if (resStr.length > 1){
    return resStr
  }else {
    return "0" + resStr;
  }
}