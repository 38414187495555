import React, { Fragment } from "react";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Clock from "./Clock";
import RoomName from "./RoomName";
import { useCLOCK } from "../../contexts/ClockContext";

import {
    Grid
} from "@mui/material";
import Logo from "./Logo";


export default function NavBar(props) {
    const { clock, roomname, logo } = props;
    const { big } = useCLOCK();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (

        <Grid item container spacing={0}>
            {mobile && (
                <Fragment>
                    <Grid container item sm={6} xs={6}
                        direction="row"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        style={{
                            backgroundColor: ""
                        }} >
                        {logo && (<Logo />)}
                    </Grid>
                    {!big && (
                        <Fragment>
                            <Grid item sm={6} xs={6}>
                                {roomname && (<RoomName mobile={true} />)}
                            </Grid>
                            <Grid item sm={12} xs={12}>
                                {clock && (<Clock mobile={true} />)}
                            </Grid>
                        </Fragment>
                    )}
                    {big && (
                        <Grid item sm={6} xs={6} align="center">
                            <RoomName mobile={true} />
                        </Grid>
                    )}

                </Fragment>

            )}
            {!mobile && (
                <Fragment>
                    <Grid container item lg={5} md={5} sm={5} xs={12}
                        direction="row"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        style={{
                            backgroundColor: ""
                        }} >
                        {logo && (<Logo />)}
                    </Grid>
                    <Grid container item lg={3} md={3} sm={3} xs={4}
                        direction="row"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        style={{
                        }}>
                        {!big && clock && (
                            <Clock />
                        )}
                    </Grid>
                    <Grid container item lg={4} md={4} sm={4} xs={8}
                        direction="row"
                        display="flex"
                        alignItems="center"
                        justifyContent="left"
                        style={{
                        }}>
                        {roomname && (<RoomName />)}
                    </Grid>
                </Fragment>
            )
            }
        </Grid >
    );
}
