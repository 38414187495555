import React, { useState, useEffect, Fragment } from "react";

import { useSTATUS, SCREENSTATUS } from "../contexts/StatusContext";
import { useAuth } from "../contexts/AuthContext";
import { Container, Box } from "@mui/material";


import NavBar from "../components/main/NavBar";
import BottomBar from "../components/main/BottomBar";
import Led from "../components/main/Led";
import Checkins from "../components/spacejoin/Checkins";
import Events from "../components/space/Events";

import background from "../assets/img/background-air.png";
import AreaCheckin from "../components/main/AreaCheckin";

import { EventsProvider } from "../contexts/EventsContext";
import WelcomeScreen from "../components/spaceguest/Welcome";

export default function Dashboard() {
  const { status } = useSTATUS();

  const [color, setColor] = useState();
  const { display } = useAuth();

  useEffect(() => {
    if (display && display.type === "space") {
      switch (status) {
        case SCREENSTATUS.LOADING:
          setColor("rgba(223, 234, 0, 0.418)");
          break;
        case SCREENSTATUS.BUSY:
          setColor("rgba(117, 15, 15, 0.418)");
          break;
        case SCREENSTATUS.FREE:
          setColor("rgba(78, 150, 60, 0.418)");
          break;
        default:
          setColor("rgba(78, 150, 60, 0.418)");
      }
    }
  }, [status, display]);

  // grön setColor("rgba(101, 219, 72, 0.418)");
  // röd setColor("rgba(223, 0, 0, 0.418)");

  const getBackground = () => {
    if (display && display.displaySettings.background) {
      return display.displaySettings.background;
    } else {
      return background;
    }
  }

  return (
    <Container
      className="maincontainer"
      style={{
        backgroundColor: color,
        backgroundImage: `url(${getBackground()})`,
        padding: "30px",
        paddingBottom: "50px"
      }}
    >
      {display?.deviceSettings.led && (
        <Led />
      )}

      {display?.type === "spacejoin" && (
        <Fragment>
          <Box sx={{ height: '15%', backgroundColor: "" }}>
            <NavBar clock={true} roomname={true} logo={true} />
          </Box>
          <Box sx={{ height: '80%', backgroundColor: "" }}>
            <Checkins />
          </Box>
          <Box sx={{ height: '5%', backgroundColor: "" }}>
            <BottomBar />
          </Box>
        </Fragment>
      )}

      {display?.type === "space" && (
        <Fragment>
          <Box sx={{ height: '10%', backgroundColor: "" }}>
            <NavBar clock={true} roomname={true} logo={true} />
          </Box>
          <Box sx={{ height: '85%', backgroundColor: "" }}>
            <EventsProvider>
              <Events />
            </EventsProvider>
          </Box>
          <Box sx={{ height: '5%', backgroundColor: "" }}>
            <BottomBar />
          </Box>
          <AreaCheckin />
        </Fragment>
      )}

      {display?.type === "spaceguest" && (
        <Fragment>
          <Box sx={{ height: '10%', backgroundColor: "" }}>
            <NavBar clock={false} roomname={false} logo={true} />
          </Box>
          <Box sx={{ height: '85%', backgroundColor: "" }}>
            <WelcomeScreen />
          </Box>
          <Box sx={{ height: '5%', backgroundColor: "" }}>
            <BottomBar />
          </Box>
        </Fragment>
      )}
    </Container >
  )
}
