import React, { Fragment } from "react";
import {
    Box,
    Grid,
    Typography
} from "@mui/material";
import Checkin from "./Checkin";

export default function Area(props) {
    const { area, checkins } = props;

    return (
        <Fragment>
            <Box sx={{ height: '100%' }}>
                <Grid item container spacing={0} >
                    <Grid item container lg={2} md={2} sm={12} xs={12} direction="row" alignItems="center" style={{ borderRight: "2px  solid #ffffff" }}>
                        <div style={{ backgroundColor: "green", position: "relative" }}>
                            <Typography variant="h2" noWrap={true} className="rotatedHeader">
                                {area.name}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item container lg={8} md={8} sm={12} xs={12} >
                        <Grid container spacing={0} >
                            {checkins.map((checkin) => {
                                return (
                                    <Grid item lg={12} md={12} sm={12} xs={12} align="left" className="infoBox" >
                                        <Checkin key={checkin.id} checkin={checkin} />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Fragment >
    );
}
